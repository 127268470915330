@import '../helpers';

// .c-filters {}

@each $name in header footer {
  .c-filters__form-#{$name} {
    background: $gray-100;
    margin-bottom: space(6);
    display: flex;
    align-items: center;
    margin-left: -(#{space(2)});
    margin-right: -(#{space(2)});
    padding: space(2) space(4);
  }
  .c-filters__form-#{$name}-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.c-filters__form-toggle {
  display: flex;
  align-items: center;
}

.c-filters__form-toggle {
  cursor: pointer;
  i {
    font-size: 24px;
  }
  &:hover {
    i,
    h2 {
      color: $brand-primary;
    }
  }
}

.c-filters__toggle-bar {
  cursor: pointer;
  padding-top: space(5);
  height: 100%;
  border: 0;
  position: relative;
  background: $gray-100;
  border-right: 1px solid $gray-200;
  &:focus,
  &:hover {
    background: $brand-primary;
    .c-filters__toggle-bar-title i,
    .c-filters__toggle-bar-title h2 {
      color: $white;
    }
  }
  &:active {
    background: darken($brand-primary, 5%);
    .c-filters__toggle-bar-title i,
    .c-filters__toggle-bar-title h2 {
      color: $white;
    }
  }
}

.c-filters__toggle-bar-title {
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  position: sticky;
  top: space(5);
}

.c-filters__toggle-bar-title i {
  font-size: 24px;
  transition: .25s transform ease-in-out;
  transform-origin: center center;
  &.collapsed {
    transform: rotate(180deg);
  }
}

.c-filters__form {
  padding: space(4) space(2);
  padding-bottom: space(6);
  background: $gray-050;
  border-left: 3px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-bottom: 0;
  position: absolute;
  overflow: hidden;
  max-width: $filter-form-width-sm;
  width: $filter-form-width-sm;
  @include media-breakpoint-up(md) {
    max-width: $filter-form-width-md;
    width: $filter-form-width-md;
  }
  @include media-breakpoint-up(lg) {
    max-width: $filter-form-width-lg;
    width: $filter-form-width-lg;
  }
  @include media-breakpoint-up(xl) {
    max-width: $filter-form-width-xl;
    width: $filter-form-width-xl;
  }
  transition: max-width .15s ease-in-out, box-shadow .25s ease-in-out;
  padding: 0;
  opacity: 1;
  pointer-events: all;
  right: $filters-width;
  top: 0;
  bottom: 0;
  z-index: 100000;
  box-shadow: -15px 0px 20px -10px rgba(0,0,0,.1);
  &.collapsed {
    right: calc(-100% + #{$filters-width * 2});
    max-width: 0;
    pointer-events: none;
  }
}

.c-filters__form .c-form {
  min-width: $filter-form-width-sm;
  padding: 0 space(2) space(4) space(2);
  @include media-breakpoint-up(md) {
    min-width: $filter-form-width-md;
  }
  @include media-breakpoint-up(lg) {
    min-width: $filter-form-width-lg;
  }
  @include media-breakpoint-up(xl) {
    min-width: $filter-form-width-xl;
  }
}
