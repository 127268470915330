@import '../helpers';

.c-score {
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-score--warning {
  background: $brand-warning;
  border: 4px solid $white
}





