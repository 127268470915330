@import '../helpers'

$percent-bar-height: 35px
$border-radius-default: 4px

.c-percent-bar
  height: $percent-bar-height
  background: $gray-200
  width: 100%
  display: flex
  border-radius: $border-radius-default
  overflow: hidden
  margin: 3px 5px
  &:first-of-type
    margin-left: 0
  &:last-of-type
    margin-right: 0

.c-percent-bar__segment
  background-color: map-get($chart-colors, 1)
  display: flex
  align-items: center
  line-height: 17/15
  font-size: rem(15px)
  &,
  span:last-of-type
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  span
    opacity: .95
    transition: .1s opacity ease-in-out
  span:first-child
    font-weight: 600
    padding-left: space(2)
    margin-right: space(1)
  span:last-child
    padding-right: space(2)
  &:hover
    span
      opacity: 1
  @each $name, $color in $chart-colors
    &--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)

  @each $name, $color in $chart-colors-mono
    &-mono--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)

  @each $name, $color in $chart-colors-mono-blue
    &-mono-blue--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)

  @each $name, $color in $chart-colors-duo
    &-duo--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)

  @each $name, $color in $chart-colors-duo-blue
    &-duo-blue--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)

  @each $name, $color in $store-type-colors
    &--#{$name}
      background-color: $color
      color: determine-color-for-best-contrast($color)
