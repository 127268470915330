@import '../helpers'

.c-tooltip-chart
  @include tooltip
  background: $white

.c-tooltip-chart__value-wrapper
  padding: space(4)
  border-bottom: 1px solid $gray-400
  &:last-of-type
    border: 0

.c-tooltip-chart__title,
.c-tooltip-chart__date
  margin-bottom: rem(3px)

.c-tooltip-chart__date
  @include font-light()
  display: block

.c-tooltip-chart__value
  display: block
  margin-bottom: space(1)
  &:not(.c-metric-value)
    @include data-metric()
  @each $index, $color in $chart-colors
    &--#{$index}
      color: $color !important

.c-tooltip-chart__legend
  display: flex
  align-items: center
  margin-top: space(2)

.c-tooltip-chart__legend-swatch
  @include swatch(rem(20px))

.c-tooltip-chart__legend-label
  @include bolden
