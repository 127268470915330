@import '../helpers';

.c-page-section {
  margin-bottom: space(6);
  position: relative;
  &:last-child {
    margin-bottom: space(10);
  }
}

.c-page-section--no-space {
  margin-bottom: 0;
}
