@import '../../helpers';

.modal-dialog {
  padding-top: 200px;
}

.modal-header {
  background: $brand-blue-d;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.modal-title {
  color: $white;
}

.modal-header button {
  color: $white;
  text-shadow: none;
  &:hover {
    color: $white;
  }
}

.modal-header--simple-success,
.modal-header--simple-warning,
.modal-header--simple-danger,
.modal-header--warning,
.modal-header--danger,
.modal-header--success,
.modal-header--signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
  .modal-title {
    padding: space(2);
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .03em;
    font-weight: 400;
  }
  &::before {
    @include font-icon(100px);
    content: '';
    margin-top: -25px;
    margin-bottom: space(4);
  }
  button {
    position: absolute;
    right: 12px;
    top: 12px;
    &,
    &:hover {
      color: $black;
    }
  }
  ~ .modal-body {
    background: $gray-100;
  }
  ~ .modal-body,
  ~ .modal-footer {
    border: 0;
  }
  ~ .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modal-header--simple-success {
  .modal-title {
    background: $brand-success;
  }
}

.modal-header--simple-warning {
  .modal-title {
    background: $brand-warning;
  }
}

.modal-header--simple-danger {
  .modal-title {
    background: $brand-danger;
  }
}

.modal-header--success {
  &::before {
    content: '\f058';
    font-weight: 800;
    color: $brand-success;
  }
  .modal-title {
    background: $brand-success;
  }
}

.modal-header--warning {
  &::before {
    content: '\f071';
    font-weight: 800;
    color: $brand-warning;
  }
  .modal-title {
    background: $brand-warning;
  }
}

.modal-header--danger {
  &::before {
    content: '\f071';
    font-weight: 800;
    color: $brand-danger;
  }
  .modal-title {
    background: $brand-danger;
  }
}

.modal-header--signature {
  &::before {
    content: '\f573';
    font-weight: 400;
    color: $white;
    background: $brand-blue-d;
    padding: space(4);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    min-height: 150px;
    min-width: 150px;
    padding-left: 35px; // Center icon in circle
  }
  .modal-title {
    background: $brand-blue-d;
  }
}
