@import '../helpers';

.c-file-input {
  display: flex;
  align-items: center;
}

.c-file-input input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.c-file-input__btn,
.c-file-input__btn--outline {
  @include button($btn-padding-y: 2px, $btn-padding-x: space(4));
  margin-bottom: 0;
  cursor: pointer;
}

.c-file-input__btn {
  @include button-variant($brand-primary, $brand-primary);
}

.c-file-input__btn--outline {
  @include button-outline-variant($brand-primary, $brand-primary);
  &:hover {
    color: $white;
  }
}

.c-file-input input:focus + label,
.c-file-input input:focus + label:hover {
  background: $white;
  border-color: $brand-accent;
  color: $black;
}

.c-file-input__file-label {
  font-style: italic;
  margin-left: space(2);
}
