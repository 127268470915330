@import '../helpers';

.c-info-box {
  background-color: $gray-100;
  padding: space(3) space(4);
  border-radius: 3px;
}

.c-info-box__header {
  & h3, & h4, & h5 {
    color: $black;
    font-size: $font-size-default;
    font-weight: bold;
  }
}

.c-info-box__content p {
  margin-bottom: 0.5rem;
}
