@use "sass:math";
@import '../helpers';

.c-card {
  border-radius: 3px;
}

@each $size in (1, 2, 3, 4) {
  .c-card--#{$size}-up {
    flex: 0 1 percentage(math.div(1, $size));
  }
}

.c-card--child {
  padding: 0 space(1);
  margin-bottom: space(2);
  border-radius: 3px;
  max-width: 100%;
  @include media-breakpoint-down(md) {
    flex: 0 1 100%;
  }
}

.c-card__content-table,
.c-card__content-children {
  display: flex;
  flex-wrap: wrap;
  background: $card-bg-d;
  padding: space(2) space(1) space(1);
  border-radius: 3px;
}

.c-card__content-table--light {
  background: $white
}

.c-card__content-table {
  padding: space(2);
}

.c-card--child > .c-card__content {
  height: 100%;
  background: $white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}

.c-card__header {
  padding: space(1) 0 space(2) 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: $card-header-bg;
  flex: 1 1 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding-left: space(3);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.c-card__header h3,
.c-card__header--secondary h4 {
  color: $black;
  text-transform: uppercase;
}

.c-card__header--main {
  border-radius: 0;
}

.c-card__header--secondary {
  background: $card-header-bg-l;
  padding: space(1);
  border-bottom: 1px solid $card-header-bg-l;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-transform: none;
}

.c-card__header--radius-btm {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.c-card__heading {
  display: flex;
  align-items: center;
  background: transparent;
  color: $gray-900;
  flex: 1 1 auto;
}

.c-card__heading--sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.c-card__heading-addendum {
  margin-left: auto;
  padding-left: space(2);
  font-size: rem(13px);
  line-height: line-height(16, 13);
  margin-right: space(1);
}

.c-card__content {
  position: relative;
}

.c-card__content > h4 {
  background: $white;
  padding: space(1) 0;
  border-bottom: 1px solid $gray-300;
}

.c-card__content--indented {
  padding-left: calc(#{$badge-min-width} + #{space(3)});
}

.c-card__body {
  padding: space(2) space(3);
  position: relative;
}

.c-card__legend {
  min-width: 25%;
}

.c-card__veil {
  position: absolute;
  z-index: 10;
  background: rgba($gray-500, .95);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-card__veil p {
  color: $white;
  font-size: rem(16px);
  line-height: math.div(18, 16);
  letter-spacing: .03em;
  font-style: italic;
  margin-bottom: 0;
}
