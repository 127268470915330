@import '../helpers';

.c-type-icon {
  margin-right: space(1);
  &::before {
    @include font-icon(20px);
    content: '';
  }
}

$types: (
  existing: '\f54e',
  new: '\f0a3',
  relocation: '\f602',
  renovation: '\f85e',
);

@each $type-name, $type-char in $types {
  .c-type-icon--#{$type-name} {
    &::before {
      content: $type-char;
    }
  }
}
