@import '../helpers';

.c-nav-page {
  background: $gray-050;
  border-bottom: 0;
}

.c-nav-page--bordered {
  border-bottom: 1px solid $gray-100;
}

.c-nav-page .nav-link {
  border: 0;
  border-radius: 0;
  box-shadow: 0px -2px 0px 0px transparent inset;
  &:first-child {
    padding-left: space(4);
  }
  &.active {
    font-weight: 700;
    background: $gray-050;
    color: $black;
    box-shadow: 0px -2px 0px 0px $gray-900 inset;
  }
}
