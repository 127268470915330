@import '../helpers';

/* .c-heading-row { } */

.c-heading-row--first {
  padding-top: space(2);
}

.c-heading-row--bordered {
  border-bottom: 2px solid $gray-900;
  padding-bottom: space(2);
}

.c-heading-row--clickable {
  cursor: pointer;
  &:focus,
  &:hover {
    background: $gray-200;
  }
  &:active {
    background: $gray-300;
  }
  &[aria-expanded="true"] {
    background: $gray-800;
    border-color: $black;
    &,
    h2 {
      color: $white;
    }
    .fa-angle-down {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
}

.c-heading-row__content,
.c-heading-row__title {
  padding-left: space(4);
  padding-right: space(4);
}

.c-heading-row__content {
  padding-top: space(2);
  padding-bottom: space(2);
}

.c-heading-row__content--light {
  background: $gray-100;
  border-bottom: 2px solid $gray-200;
}
