@import '../helpers';

.c-state-icon {
  font-style: normal;
  line-height: 0;
  &::before {
    content: '';
    font-style: normal;
    @include font-icon;
    font-size: rem(32px);
    font-weight: 900;
  }
}

.c-state-icon--neutral {
  &::before {
    content: '\f059';
    color: $gray-500;
  }
}

.c-state-icon--positive {
  &::before {
    content: '\f058';
    color: $brand-success;
  }
}

.c-state-icon--negative {
  &::before {
    content: '\f057';
    color: $brand-danger;
  }
}

$states: (
  approval     : '\f164',
  ready        : '\f164',
  draft        : '\f573',
  review       : '\f002',
  score        : '\f559',
  attachment   : '\f0c6',
  comment      : '\f075',
  unscored     : '\f359',
  progress     : '\f252',
  conformance  : '\f071',
  improve      : '\f7d9',
  info         : '\f05a',
  note         : '\f249',
  dispute      : '\f024',
  status       : '\f03a',
);

@each $state-name, $state-code in $states {
  .c-state-icon[class*="#{$state-name}"] {
    &::before {
      content: $state-code;
      /* @if ($state-name == ready) {
        color: $brand-success;
      } */
    }
  }
}

.c-state-icon--dispute {
  &::before {
    font-size: rem(16px);
    color: $white;
  }
  border-color: $brand-warning;
  background: $brand-warning;
}
