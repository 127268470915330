@import '../helpers';

$code-color: $gray-600;

.c-code--simple {
  color: $code-color;
  margin-left: space(2);
  margin-right: space(2);
  font-weight: normal;
  font-size: 95%;
}

.c-code--light {
  color: rgba($white, .85);
}

.c-code--right {
  padding-left: space(2);
  border-left: 1px solid $code-color;
}
