@import '../../helpers';

.alert {
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;
}

.alert p {
  margin: 0;
  padding: space(2) 0;
}

.alert .container {
  position: relative;
  display: flex;
  align-items: center;
}

.alert .close {
  position: static;
  margin-left: auto;
  color: $gray-900;
  opacity: .9;
}
