$props: (
  middle,
  top,
  bottom,
);

@each $prop in $props {
  .v-align--#{$prop} {
    vertical-align: $prop !important;
  }
}
