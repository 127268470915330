@import '../helpers';

.c-badge {
  padding: space(1) space(4);
  background: $gray-100;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  letter-spacing: .05em;
  display: inline-flex;
  justify-content: center;
  min-width: $badge-min-width;
}

.c-badge--plain {
  font-size: small;
  font-weight: normal;
}

.c-badge--light {
  background: $white;
  border: 1px solid $gray-200;
  color: $black;
}

.c-badge--medium {
  background: $gray-200;
  border: 1px solid $white;
  color: $black;
}

.c-badge--dark {
  background: $gray-900;
  border: 1px solid $gray-050;
  color: $white;
}

.c-badge--secondary {
  background: $brand-secondary;
  color: $black;
}

.c-badge--success {
  background: $brand-success;
  color: $white;
}

.c-badge--danger {
  background: $brand-danger;
  color: $white;
}

.c-badge--warning {
  background: $brand-warning;
  color: $white;
}

.c-badge--xs {
  font-size: rem(12);
  line-height: 1;
  padding: rem(5) space(2);
  min-width: rem(25);
}

.c-badge--sm {
  font-size: small;
  padding-left: space(2);
  padding-right: space(2);
  min-width: 50px;
}

.c-badge--lg {
  border-radius: 35px;
  padding-left: space(6);
  padding-right: space(6);
}

.c-badge--floating {
  position: absolute;
  right: 0px;
  top: 0px;
  font-weight: unset;
  font-size: rem(12);
  line-height: 1;
  padding: rem(3) space(1);
  min-width: unset;
}
