// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Typography
// ---------------------------------------------------------------------------
// Mixins for each font-face their fonts
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@import
  './variables',
  '../_tools/index';

// copied from https://engageinteractive.co.uk/blog/top-10-scss-mixins
@mixin font-open-sans($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $font-family-sans-serif;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeelegibility;
}

@mixin font-montserrat($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $headings-font-family;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  letter-spacing: .03em;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeelegibility;
}

@mixin font-default($size: false, $color: false, $lh: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: 400, $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: 400, $color: $color, $lh: $lh);
  }
}

@mixin font-light($size: false, $color: false, $lh: false) {
  @include font-open-sans($size: $size, $weight: font-weight(light), $color: $color, $lh: $lh);
}

@mixin font-semibold($size: false, $color: false, $lh: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: font-weight(semibold), $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: font-weight(semibold), $color: $color, $lh: $lh);
  }
}

@mixin font-bold($size: false, $color: false, $lh: false, $font: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: 700, $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: 700, $color: $color, $lh: $lh);
  }
}

@mixin font-extrabold($size: false, $color: false, $lh: false, $font: false, $heading: false) {
  @include font-open-sans($size: $size, $weight: font-weight(extrabold), $color: $color, $lh: $lh);
}

@mixin font-base($color: $body-color) {
  @include font-default($color: $color);
}

@mixin font-heading($size: false, $color: false, $lh: false, $weight: font-weight(bold)) {
  @include font-montserrat($size: $size, $weight: $weight, $color: $color, $lh: $lh);
}

@mixin h1 {
  @include font-heading(rem(map-get($heading-sizes, 1)), $color: $brand-secondary, $weight: 900, $lh: line-height(58, 45))
}

@mixin h2 {
  @include font-heading(rem(map-get($heading-sizes, 2)), $color: $black, $weight: 700, $lh: line-height(24, 20));
}

@mixin h3 {
  @include font-heading(rem(map-get($heading-sizes, 3)), $gray-800, $weight: 700, $lh: line-height(22, 18));
}

@mixin h4 {
  @include font-extrabold(rem(map-get($heading-sizes, 4)), $color: $gray-800, $lh: line-height(22, 18), $heading: false);
}

@mixin default-body-font($size: $font-body-size, $color: $body-color) {
  @if ($use-light-font) {
    @include font-light($size: $size, $color: $color)
  } @else {
    @include font-default($size: $size, $color: $color)
  }
}

@mixin data-metric($size: rem($metric-size), $color: $body-color) {
  @include font-bold($size, $color: $color, $lh: line-height($metric-size, $metric-size));
}

@mixin data-metric-no-data($size: 14) {
  @include font-default(rem($size), $color: $gray-600, $lh: line-height($size + 2, $size));
}

@mixin font-icon($size: rem(28), $weight: 400) {
  font-family: 'Font Awesome 5 Pro';
  font-size: $size;
  @if ($weight) {
    font-weight: $weight;
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin bolden {
  letter-spacing: .03em;
  font-weight: 700;
}

@mixin text-md {
  font-size: rem(15px) !important;
  line-height: line-height(18, 15) !important;
}

@mixin text-lg {
  font-size: rem(18px) !important;
  line-height: line-height(20, 18) !important;
}

@mixin text-xl {
  font-size: rem(24px) !important;
  line-height: line-height(26, 24) !important;
}
