@import '../helpers';

.c-header {
  background: $white;
  border-bottom: 1px solid $border-color-primary;
}

.c-header__brand {
  margin-top: space(2);
  margin-bottom: space(2);
  color: $brand-secondary;
  @include font-heading(rem(36px), $weight: 800);
  text-transform: uppercase;
  pading-top: space(2);
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
  @include media-breakpoint-down(sm) {
    font-size: rem(28px);
    line-height: 32px;
    width: 100%;
    text-align: center;
  }
}

.c-header__nav {
  margin-left: auto;
  display: flex;
  align-items: stretch;
  padding: 0;
  @include media-breakpoint-down(sm) {
    width: 100%;
    justify-content: center;
  }
}

.c-header__nav .c-dropdown {
  margin-right: space(2, true);
}

.c-header__nav .dropdown-toggle {
  padding-left: space(2);
  padding-right: space(2);
}

.c-header__nav .dropdown-toggle {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
}
