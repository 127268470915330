@import "../helpers";

.c-rails-env-banner {

  &.production { display: none; }
  
  width: 100%;
  padding: 10px 0;
  text-align: center;

  &.staging {
    background-color: lighten($brand-warning, 10%);
    color: darken($brand-warning, 20%);
  }

  &.development {
    background-color: lighten($brand-success, 10%);
    color: darken($success, 20%);
  }

  .c-rails-env-banner__env {
    font-size: 1.2 rem;
  }

}