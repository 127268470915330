@import '../../helpers';

.nav-item,
.nav-link {
  display: flex;
  align-items: stretch;
}

.nav-item {
  margin-left: 0;
  &.active {
    background: $gray-100;
    a {
      font-weight: bold;
      color: $black;
    }
  }
}

.nav-link {
  align-items: center;
  &[aria-expanded='true'] {
    font-weight: 800;
    i {
      transform: rotate(180deg);
    }
  }
}

.nav-item--bordered {
  border-right: 1px solid $gray-100
}
