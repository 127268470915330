@mixin constrained-container {
  max-width: $max-container-width;
}

@mixin transition($duration: $transition-duration, $prop: false, $timing-fn: ease-in-out) {
  @if ($prop) { transition-property: $prop; }
  transition-duration: $duration;
  transition-timing-function: $timing-fn;
}

@mixin line-height($lh, $font-size) {
  line-height: line-height($lh, $font-size)
}

@mixin button($btn-padding-y: $btn-padding-y, $btn-padding-x: $btn-padding-x, ) {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

@mixin pull-x($amount:4, $pad: true) {
  $_amount: space($amount);
  margin-left: (-#{$_amount});
  margin-right: (-#{$_amount});
  @if ($pad) {
    padding-left: $amount;
    padding-right: $amount;
  }
}

@mixin swatch($size, $color: $white) {
  flex: 0 0 auto;
  height: $size;
  width: $size;
  border-radius: 4px;
  margin-right: space(2);
  background-color: $white;
}

@mixin tooltip {
  border: 1px solid $brand-primary;
  background: $white;
  box-shadow: 0 2px 8px rgba($black, .2);
  border-radius: 4px;
  max-width: rem(500px);
}

@function determine-color-for-best-contrast($color) {
    @if (strip-unit(lightness( $color )) > 40) {
      @return black;
    }
    @else {
      @return white;
    }
}

@mixin scrollbar($width: $default-scrollbar-width) {
  overflow: auto;
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $gray-100;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-primary;
    cursor: pointer;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $brand-accent;
  }
}

@mixin init-font($name: 'icons', $base-class-name: 'icon') {
  @font-face {
    font-family: '#{$name}';
    src:
      url('../../../fonts/#{$name}/#{$name}.woff2?9estnn') format('woff2'),
      url('../../../fonts/#{$name}/#{$name}.ttf?9estnn') format('truetype'),
      url('../../../fonts/#{$name}/#{$name}.woff?9estnn') format('woff'),
      url('../../../fonts/#{$name}/#{$name}.svg?9estnn##{$name}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="#{$base-class-name}-"], [class*=" #{$base-class-name}-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $name !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before {
      display: inline;
      content: '';
    }
  }

  @each $name, $char in $icons {
    .icon-#{$name}::before {
      content: $char;
    }
  }
}
