@import '../helpers';

.h1,
h1 {
  letter-spacing: .03em;
  @include h1;
}

.h2,
h2 {
  letter-spacing: .02em;
  @include h2;
}

.h3,
h3 {
  letter-spacing: .02em;
  @include h3;
}

.h4,
h4 {
  letter-spacing: .02em;
  @include h4;
}
