@import '../../helpers';

.dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none
}

.dropdown-menu {
  margin-right: -1px;
}

.dropdown-item a {
  display: flex;
  align-items: center;
}

.dropdown-item > i {
  flex: 1 1 50px;
  min-width: 20px;
}

.dropdown-item > span {
  flex: 1 1 auto;
}
