@use "sass:math";
@import '../helpers';

.c-aside {
  width: $aside-width;
}

.c-aside--right {
  border-left:  2px solid $brand-accent-2;
}

.c-aside--left {
  /* border-right: 2px solid $brand-accent-2; */
}

.c-aside__rule {
  border-bottom: $aside-rule-height solid $gray-100;
  margin-bottom: space(4);
}

.c-aside__rule--secondary {
  border-width: math.div($aside-rule-height, 2);
  border-color: $white;
}

.c-aside__heading {
  margin-bottom: space(4);
  padding-bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

.c-aside__heading-text {
  margin-bottom: space(1);
  border-bottom: 1px solid $brand-blue-d;
  width: 100%;
  background: $card-bg-d;
}

.c-aside__heading-text {
  margin-bottom: 0;
  color: $brand-accent-2;
  padding: space(2) space(6) space(2) space(2);
}

$assessment-states: (
  'not-started'          : ($brand-warning, '\f06a', 600),
  'processing-queued'    : ($gray-800, '\f274', 600),
  'processing'           : ($gray-800, '\f252', 600),
  'processing-complete'  : ($brand-success, '\f058', 600),
  'error'                : ($brand-danger, '\f071', 600),
  'disabled'             : ($gray-800, '\f1da', 600),
);

.c-aside__heading-context {
  background: $white;
  padding: space(2) space(5) space(2) space(2);
  color: $gray-700;
  font-size: rem(15px);
  line-height: line-height(15, 13);
  font-weight: 600;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: space(2);
}

$heading-status-icon-size: 30px;
.c-aside__heading-status {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  &:before {
    @include font-icon;
    content: '';
    font-weight: 600;
    font-size: 20px;
    flex: 1 1 $heading-status-icon-size;
    width: $heading-status-icon-size;
    max-width: $heading-status-icon-size;
  }
}

.c-aside__heading {
  @each $name, $color-code in $assessment-states {
    $color: darken(nth($color-code, 1), 2%);
    $code: nth($color-code, 2);
    $weight: nth($color-code, 3);
    &--#{$name} {
      .c-aside__heading-context {
        color: $color;
        background: rgba($color, .15);
        @if ($name == error) {
          $color: $white;
        }
      }
      .c-aside__heading-status {
        &:before {
          content: $code;
          font-weight: $weight;
        }
      }
    }
  }
}

.c-aside__heading-message {
  font-weight: normal;
  padding-left: rem($heading-status-icon-size);
  margin-top: space(1);
  display: block;
  &:empty {
    display: none;
  }
}

.c-aside__heading--disabled {
  opacity: .5;
}

.c-aside__section {
  margin-bottom: space(6);
  &:first-child {
    margin-top: space(4);
  }
}

.c-aside__section-action {
  margin-top: space(4)
}

.c-aside__section-alert {
  margin: (-#{space(4)}) (-#{space(4)}) space(4) (-#{space(4)});
  width: calc(100% + #{space(8)});
  font-weight: 600;
}

.c-aside__section-alert .alert {
  padding: space(2) space(4);
}

.c-aside__section-alert ul {
  margin-bottom: 0;
  font-weight: normal;
  padding-left: space(5);
}

.c-aside__section-content {
  padding: 0 space(5) 0 space(2);
}

.c-aside__actions {
  display: flex;
  flex-direction: column;
  margin-top: space(4);
}

.c-aside__action {
  width: 100%;
  margin-bottom: space(2);
  &:last-child {
    margin: 0;
  }
}

.c-aside__action--width-restricted {
  width: auto;
}

.c-aside__action--width-restricted > i {
  padding-left: space(6);
}

.c-aside__section-status {
  padding: space(3) space(4) space(3);
  display: flex;
  align-items: center;
  border-bottom: 5px solid $white;
}

.c-aside__section-status--columnar {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: space(6);
  padding-bottom: space(6);
  padding-right: space(6);
}

.c-aside__section-status p {
  text-align: center;
}

.c-aside__section-intro {
  font-weight: 600;
  margin-bottom: space(2);
  font-size: 18px;
  line-height: math.div(22, 18);
}

.c-aside__secondary-header {
  padding: space(2) space(6) space(2) space(4);
  background: $brand-blue-d;
  flex: 0;
  &,
  &:first-child {
    margin: 0;
  }
}

.c-aside__secondary-header h2 {
  margin-bottom: 0;
  color: $white;
}

.c-aside__section-status-icon {
  font-size: rem(75px);
  margin-bottom: space(4);
}

.c-aside__section-status .c-state-icon {
  margin-right: 0;
}

.c-aside__section-status-title {
  margin-bottom: 0;
  margin-left: space(2);
  text-transform: uppercase;
}

@each $state in success danger warning {
  $color: map-get($theme-colors, $state);
  .c-aside__section-status--#{$state} {
    background: rgba($color, .5);
    &.c-aside__section-status--bordered {
      background: none;
      border-top: 5px solid $color;
      border-bottom: 5px solid $color;
      margin-bottom: calc((-#{space(6)}) + 5px);
    }
  }
}

.c-aside__section-list {
  @include list-unstyled;
  margin-bottom: 0;
}

.c-aside__section-list-item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $gray-100;
  margin-bottom: 3px;
  padding-bottom: 3px;
  &:last-of-type {
    border-bottom: 0;
  }
}

.c-aside__section-list-item > span {
  font-weight: 600;
}

.c-aside__section-list-item a {
  font-size: 13px;
}

.c-aside__section-footer {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $gray-200;
  padding-top: space(1);
}

.c-aside__section-footer a {
  margin-right: space(2);
  &:last-of-type {
    margin: 0;
  }
}
