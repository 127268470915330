@import '../helpers';

.c-breadcrumbs { }

.c-breadcrumbs .breadcrumb {
  padding-left: 0;
  padding-right: 0;
  &:empty {
    display: none;
    margin: 0;
  }
  &:not(:empty) {
    margin-bottom: space(4, true)
  }
}

.c-breadcrumbs a {
  &.active {
    color: $link-color;
  }
}

.breadcrumb-item--with-icon {
  display: inline-flex;
  align-items: center;
}

.breadcrumb-item--with-icon i {
  margin-right: space(1);
}
