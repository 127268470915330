@import '../helpers';

.c-block-list {
  display: flex;
  flex-wrap: wrap;
}

.c-block-list__item {
  padding-right: space(4);
  margin-right: space(4);
}

.c-block-list__title {
  @include h4;
  text-transform: uppercase;
  border-bottom: 1px solid $gray-300;
  margin-bottom: space(1);
  padding: space(1) space(4) space(1) 0;
  color: $black;
}

.c-block-list__title--lg {
  @include h3;
  padding-top: 0;
}

.c-block-list__list {
  @include list-unstyled;
  margin-bottom: 0;
  padding-left: space(2);
}

.c-block-list__list-item {
  color: $gray-700;
  font-size: rem(14px);
  line-height: line-height(16, 13);
}
