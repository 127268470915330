@import '../helpers';

.c-notifications-dropdown {
  display: flex;
  align-items: stretch;
  align-self: center;
  &::after {
    display: none;
  }
}

.c-notifications-dropdown .dropdown-menu {
  padding-top: 0;
}

.c-notifications-dropdown .dropdown-toggle {
  position: relative;
  color: $gray-500;
}
.c-notifications-dropdown.show .dropdown-toggle {
  background-color: $gray-100;
}

.c-notifications-dropdown .dropdown-divider {
  margin: 0;
}

.c-notifications-dropdown .c-notifications-header {
  width: 440px;
  padding: 12px 36px 8px;
  background-color: $gray-200;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.c-notifications-dropdown .c-notifications-list {
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
}

.c-notification-item {
  padding: 12px 36px;
}

.c-notification-item .c-notification-body {
  position: relative;
  white-space: normal;
}

.c-notification-item .c-notification-delete-btn {
  visibility: hidden;
  color: $gray-500;
  &:hover {
    color: $link-hover-color;
  }
}
.c-notification-item:hover .c-notification-delete-btn {
  visibility: unset;
}

.c-notification-item .c-notification-date {
  color: $gray-500;
}

.c-notification-item .c-notification-unread-indicator {
  color: $secondary;
  font-size: 0.7em;
  position: absolute;
  left: -6px;
  top: 8px;
}
.c-notification-item--read .c-notification-unread-indicator {
  display: none;
}
