@import '../helpers';

$table-full-bleed-padding: space(4);

.c-table td {
  vertical-align: middle;
}

.c-table--bordered {
  border: 1px solid $gray-150;
}

.c-table--bordered th td,
.c-table--bordered tr td {
  border-bottom: 1px solid $gray-150;
  border-top: 0;
}

.c-table--cells-v-centered tr {
  td {
    vertical-align: middle;
  }
}

.c-table--full-bleed {
  margin-left: (-$table-full-bleed-padding);
  margin-right: (-$table-full-bleed-padding);
  width: calc(100% + #{$table-full-bleed-padding} + #{$table-full-bleed-padding});
}

.c-table--full-bleed tbody tr td,
.c-table--full-bleed thead tr th {
  &:first-of-type {
    padding-left: $table-full-bleed-padding;
  }
  &:last-of-type {
    padding-right: $table-full-bleed-padding;
  }
}

/* .c-table__heading-row {} */

.c-table__heading-row--dark td,
.c-table__heading-row--dark th {
  background: $gray-050;
  border-bottom: 0;
}

.c-table__heading--text-lg {
  @include text-lg;
}

.c-table__cell--badge {
  width: $badge-min-width;
}

.c-table__cell--score {
  width: 75px;
}

.c-table__cell--min-width > span {
  display: block;
  min-width: 175px;
}

.c-table__cell--score .c-state-icon {
  margin: 0;
}

.c-table--secondary thead th,
.c-table--secondary tbody td {
  font-size: rem(13px);
  letter-spacing: .03em;
  color: $gray-800;
  border: 0;
}

.c-table__row--light td {
  background: $white;
}

@each $state in success danger warning {
  .c-table__row--#{$state} td {
    background: rgba(map-get($theme-colors, $state), 1);
  }
}

.table-hover tr {
  &:hover,
  &:focus,
  &:active,
  &.active {
    * { color: white !important; }
  }
}

.table-hover .c-table__row--light {
  cursor: pointer;
  &:hover td {
    background: $table-hover-bg;
  }
  &:active td {
    background: $brand-blue-d;
  }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Secondary modification
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.c-table--secondary thead th {
  border-bottom: 1px solid $gray-050;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Card modification (tables within cards)
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.c-table--card {
  border-radius: 3px;
  margin-bottom: 0;
}

.c-table--card thead th,
.c-table--card tbody td {
  border-color: $card-bg-d;
}
