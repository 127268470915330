@import '../helpers';

.c-code-block {
  font-size: 10px;
  padding: space(2);
  background: $gray-200;
  max-height: 600px;
  overflow: auto;
  border-radius: 3px;
}
