@import '../helpers';

.c-list {
  position: relative;
  @include list-unstyled;
}

.c-list__item {
  margin-bottom: space(2);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-list__item--bordered {
  border-bottom: 1px solid $gray-100;
  padding-bottom: space(2);
  &:only-child,
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.c-list__heading {
  position: sticky;
  padding-top: space(2);
  padding-bottom: space(2);
  top: space(4, true);
  z-index: 10;
}

// Not great
.c-list__item a {
  &:hover {
    text-decoration: none;
    .c-badge--dark {
      background: $gray-100;
      color: $black;
    }
  }
  &:active {
    .c-badge--dark {
      background: $gray-200;
      color: $black;
    }
  }
}
