@use "sass:math"
@import '../helpers'

$chart-legend-point-size: 20px

.c-chart-legend
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  padding-left: space(4)
  margin: space(2) 0 0 space(4)

.c-chart-legend .c-chart-legend__item
  margin-right: space(6)
  margin-bottom: space(2)

.c-chart-legend--sm
  font-size: rem(14px)
.c-chart-legend--sm .c-chart-legend__swatch
  @include swatch(rem(math.div($chart-legend-point-size, 1.25)))
  margin-left: 0

.c-chart-legend--inline
  align-items: center

.c-chart-legend--inline .c-chart-legend__item,
.c-chart-legend--inline .c-chart-legend__label
  margin-bottom: 0

.c-chart-legend--inline .c-chart-legend__item
  margin-right: space(6)

.c-chart-legend--stacked
  flex-direction: column
  margin-left: 0

.c-chart-legend--stacked .c-chart-legend__item
  &:last-child
    margin-bottom: 0

.c-chart-legend--left
  justify-content: flex-start
  padding-left: 0

.c-chart-legend--secondary
  margin-top: space(5)

.c-chart-legend__item
  display: flex
  flex-direction: column
  align-items: center
  opacity: .5
  transition: .2s
  max-width: rem(300px)
  &:only-child
    min-width: 100%
  &:last-child
    margin-right: 0

.c-chart-legend__item--lg
  max-width: rem(500px)

.c-chart-legend__item--swatch
  flex-direction: row
  margin-right: space(2)
  align-items: flex-start
  margin-bottom: space(2)
  opacity: 1

.c-chart-legend__item--centered
  align-items: center

.c-chart-legend__item--baseline
  align-items: baseline

.c-chart-legend__item--swatch .c-chart-legend__label
  margin-top: -3px
  span
    text-align: left

.c-chart-legend__swatch
  @include swatch(rem($chart-legend-point-size))

.c-chart-legend__label
  margin-bottom: space(2)

.c-chart-legend__label--bold
  font-weight: 600

.c-chart-legend__label span
  display: block
  &:first-of-type
    @include bolden
    margin-bottom: space(1)
  &:last-of-type
    color: $gray-800

@each $index, $color in $chart-colors
  .c-chart-legend__swatch--#{$index}
    background-color: $color

@each $index, $color in $chart-colors-mono
  .c-chart-legend__swatch-mono--#{$index}
    background-color: $color

@each $index, $color in $chart-colors-mono-blue
  .c-chart-legend__swatch-mono-blue--#{$index}
    background-color: $color

@each $index, $color in $chart-colors-duo
  .c-chart-legend__swatch-duo--#{$index}
    background-color: $color

@each $index, $color in $chart-colors-duo-blue
  .c-chart-legend__swatch-duo-blue--#{$index}
    background-color: $color

@each $name, $color in $store-type-colors
  .c-chart-legend__swatch--#{$name}
    background-color: $color
