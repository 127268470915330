@import '../helpers';

.c-metric {
  @include data-metric;
}

.c-metric--secondary {
  color: $gray-600;
}

.c-metric--no-data {
  @include data-metric-no-data;
}

.c-metric__viz-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  @include media-breakpoint-only(md) {
    flex-direction: column;
    justify-content: center;
  }
}

/* .c-metric__viz { } */

.c-metric__viz--donut {
  min-height: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-metric__loading {
  z-index: 1;
  color: $gray-500;
  font-size: rem(75px);
}

.c-metric__loading--sm {
  font-size: rem(45px);
  padding: 3px;
}

.c-metric__title,
.c-metric__title-context {
  @include text-lg;
  text-align: center;
}

.c-metric__title-context {
  margin-top: space(1);
  @include text-md;
}
