@import '../helpers';

.c-badge-group {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: space(1);
}

.c-badge-group__title {
  font-size: 14px;
  margin-right: space(1);
}
