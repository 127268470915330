// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Color Variables
// ---------------------------------------------------------------------------
// Variables to extend/update pallete provided by Bootstrap
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


$brand-primary  : #03a3e4;
$brand-secondary: #789907;

$brand-blue-d:    #0D2C54;

$brand-accent   : #ee7f1e;
$brand-accent-2 : $brand-blue-d;

// States
$brand-info     : $brand-primary;
$brand-info-l   : #E5F2F7;
$brand-danger   : #C24644;
$brand-success  : rgba(127, 184, 0, 1);
$brand-warning  : $brand-accent;

$white          : #FFFFFF;
$gray-025       : lighten(#EEEEEE, 5%);
$gray-050       : lighten(#EEEEEE, 3%);
$gray-100       : #EEEEEE;
$gray-150       : darken($gray-100, 5%);
$gray-200       : darken($gray-100, 10%);
$gray-300       : darken($gray-100, 20%);
$gray-400       : darken($gray-100, 30%);
$gray-500       : darken($gray-100, 40%);
$gray-600       : darken($gray-100, 50%);
$gray-700       : darken($gray-100, 60%);
$gray-800       : darken($gray-100, 70%);
$gray-900       : darken($gray-100, 80%);
$gray-950       : darken($gray-100, 90%);
$black          : #000000;

$body-color     : $black;

$primary        : $brand-primary;
$secondary      : $brand-secondary;
$success        : $brand-success !default;
/* $info           : $brand-info; */
$danger         : $brand-danger;
$warning        : $brand-warning;
$light          : $gray-100;
$dark           : $gray-900;

$link-color: #0ba7e0;
$link-hover-color: #077197;

$border-color-primary: $gray-100;

$brand-colors: (
  white: $white,
  black: $black,
  primary: $brand-primary,
  secondary: $brand-secondary,
  gray-l: $gray-100,
  gray-m: $gray-500,
  gray-d: $gray-900,
  notice: $success,
  alert: $danger
);

$page-heading-color: $black;
$page-heading-secondary-color: $brand-accent-2;

$incomplete-color: #A9AEB1;
// Charts
$chart-colors: (
  0: rgba(255, 180, 0, 1), // gold    FFB400
  1: rgba(3, 163, 228, 1), // blue    03A3E4
  2: rgba(127, 184, 0, 1), // green   7FB800
  3: rgba(13, 44, 84, 1),  // violet  0D2C54
  4: #1B2F33,              // dark green     D95D39
  5: rgba(246, 81, 29, 1), // orange  F6511D
);

$chart-colors-mono: (
  0: #7FB800,
  1: #254912,
  2: $incomplete-color,
);


$store-type-colors: (
  new: #AACDEC,
  relocation: #58B4FF,
  renovation: #0076D6,
  existing: #0B4778,
);

$chart-colors-mono-blue: (
  0: #AACDEC,
  1: #58B4FF,
  2: #0076D6,
  3: #0B4778,
);

$chart-colors-duo: (
  0: #FFBC78,
  1: $incomplete-color,
);

$chart-colors-duo-blue: (
  0: #0B4778,
  1: $incomplete-color,
);

//Cards
$card-bg-d: $gray-150;
$card-bg-l: $white;
$card-header-bg: $gray-700;
$card-header-bg-l: $gray-100;
