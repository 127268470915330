@use "sass:math";
@import '../helpers';

.c-store-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-store-label__icon {
  font-size: 34px;
  margin-right: space(1);
  color: $gray-700;
}

.c-store-label__text {
  color: $gray-600;
  font-size: 13px;
  line-height: math.div(15, 13);
}

.c-store-label--stacked {
  flex-direction: column;
  .c-store-label__icon {
    margin-right: 0;
  }
}
