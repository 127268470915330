@import '../helpers';

.c-warning-message {
  display: flex;
  align-items: baseline;
}

.c-warning-message__icon {
  color: $brand-warning;
  margin-right: space(1);
  font-size: 20px;
  padding-top: 1px
}

.c-warning-message__title,
.c-warning-message__description {
  margin-bottom: 0;
}

.c-warning-message__title {
  font-weight: bold;
}
