@import '../../helpers';

.btn-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-md {
  padding: space(1) space(5);
}

.btn-link--light {
  color: white;
  &:hover,
  &:focus {
    color: darken(white, 5%);
  }
}

.btn--tall {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.btn--shout {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .03em;
}

.btn--with-icon {
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn--with-icon > i {
  margin-left: auto;
}

.btn-outline-primary.active.disabled,
.btn-outline-primary.active:disabled {
  background-color: desaturate($link-color, 5%);
  color: $white;

}

.btn-group-toggle .btn.disabled {
  pointer-events: none;
}

.btn-group-toggle {
  .btn {
    cursor: pointer;
    &[disabled],
    &.disabled {
      border-color: $gray-300;
      color: $gray-300;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  &.loading {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .btn {
      pointer-events: none;
    }
    &::before {
      content: 'LOADING';
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-weight: 800;
      font-size: 20px;
      line-height: 1;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      background: rgba($white, .8);
      z-index: 100;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 110;
      width: 0%;
      top: 0;
      left: 0;
      bottom: 0;
      background: $brand-success;
      animation-name: loading;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: .5;
    }
  }
}

.btn[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  &:not([class*=outline]) {
    background: $gray-100 !important;
    color: $gray-500 !important;
    border-color: $gray-200 !important;
  }
}


@keyframes loading {
  from {width: 0%}
  to {width: 100%; opacity: 1}
}
