@import '../../helpers';

.card-updated-at {
  color: $gray-700;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  margin-bottom: 0;
}

.card-text {
  max-width: 100ch;
}
