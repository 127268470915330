@import '../helpers';

.c-dropdown {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  &.show {
    background: $gray-100;
  }
  &::after {
    display: none;
  }
}

.c-dropdown .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.c-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}
