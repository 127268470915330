@import '../helpers';

.c-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: $gray-100;
  }
}

