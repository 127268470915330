@import '../helpers';

$button-size: 2.125rem;


.trix-button-group,
.trix-content {
  background-color: white;
}

.trix-container {
  position: relative;
  min-width: 100%;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 1px;
    right: 1px;
    left: 1px;
    height: calc(#{$button-size} + 1px);
    border-top: 1px solid $input-border-color;
    background: $gray-050;
    z-index: 1;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

trix-toolbar .trix-button-row {
  justify-content: space-between;
  flex-wrap: wrap;
  background: $white;
  border: 1px solid $input-border-color;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: $gray-050;
}

trix-toolbar .trix-button-group,
trix-toolbar .trix-button {
  border: 0;
  margin: 0;
}

trix-toolbar .trix-button-group,
trix-toolbar .trix-button-group:not(:first-child) {
  margin: 0;
}

trix-toolbar .trix-button-group:nth-child(3),
trix-toolbar .trix-button-group:last-child {
  position: absolute;
  bottom: 1px;
  z-index: 10;
}

trix-toolbar .trix-button-group:nth-child(3) {
  left: 1px;
  border-radius: 0;
  border-bottom-left-radius: 3px;
  .trix-button {
    width: auto;
    max-width: none;
    text-indent: 1.5rem;
    padding-right: 12px;
    font-weight: normal;
    font-size: 13px;
    color: $black;
  }
  .trix-button--icon::before {
    background-size: auto;
    background-position: .4em center;
  }
}

trix-toolbar .trix-button-group:last-child {
  right: 1px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
}

.trix-button-group-spacer {
  display: none;
}

trix-toolbar .trix-button {
  width: $button-size;
  height: $button-size;
  border-color: $input-border-color;
  opacity: .65;
  &[class$='active'] {
    opacity: 1;
  }
  &:hover,
  &:focus {
    opacity: 1;
    background: $gray-100;
  }
}

trix-toolbar .trix-button--icon::before {
  background-size: 70% 70%;
}

trix-editor {
  min-width: 100%;
  display: block;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: $input-border-color;
  min-height: 150px;
  font-size: 1rem;
  padding-bottom: calc(#{$button-size} + .4em);
}

trix-editor:empty:not(:focus)::before {
  font-size: 1rem;
}
