@import '../helpers';

/* .c-form */

.c-form__section {
  margin-bottom: space(4);
}

.c-form__section-heading {
  @include font-default(rem(20px));
  text-transform: uppercase;
}

.c-form__actions {
  display: flex;
  align-items: center;
  margin-top: space(5);
  padding-top: space(4);
  padding-bottom: space(4);
}

.c-form__actions--in-modal-content {
  margin-right: -15px;
  margin-left: -15px;
  padding: space(2) 15px space(2) 15px;
  margin-top: space(4);
  border-top: 1px solid $gray-100;
  justify-content: flex-end;
}

.c-form__actions input[type=submit],
.c-form__actions button {
  flex: 0 0 100%;
}

.c-form__actions--right {
  justify-content: flex-end;
}

.c-form__actions--dark {
  background: $gray-800;
}

.c-form__actions--full-width {
  margin-left: (-#{space(4)});
  margin-right: (-#{space(4)});
}
