@import '../helpers';

.c-table-faux {
  display: table;
  width: 100%;
}
.c-table-faux--sm {
}

.c-table-faux__header {
  display: table-row;
}

.c-table-faux__header .c-table-faux__cell {
  background: $gray-100;
  font-weight: 600;
}

.c-table-faux__row {
  display: table-row;
}

.c-table-faux__row .c-table-faux__cell {
}

.c-table-faux__cell {
  display: table-cell;
  padding: space(1) space(2);
}

.c-table-faux--sm .c-table-faux__cell {
  font-size: rem(12px);
}
