@use "sass:math";
@import '../helpers';

$timeline-state-size: 50px;

$card-color-dark: $brand-blue-d;
$card-color-default: $gray-100;
$card-border-color: darken($card-color-default, 5%);
$card-border-width: 2px;

.c-timeline-card {
  display: flex;
  margin-bottom: space(2);
  align-items: flex-start;
  position: relative;
  transition: .5s max-height ease-in-out, .25s opacity ease-in-out, .25s margin-bottom ease-in-out;
  max-height: 2000px;
  &::before {
    position: absolute;
    height: $card-border-width;
    width: $timeline-state-size;
    top: math.div($timeline-state-size, 2);
    left: math.div(-$timeline-state-size, 2);
    background: $brand-accent-2;
    content: '';
    z-index: 0;
  }
  &.removing {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
}

.c-timeline-card h3 {
  padding-top: space(1);
}

.c-timeline-card__state {
  position: relative;
  flex: 0 0 auto;
  height: $timeline-state-size;
  width: $timeline-state-size;
  z-index: 10;
  margin-top: $card-border-width;
  background: $card-color-default;
  border-radius: 50%;
  border: $card-border-width solid $brand-accent-2;
  margin-left: calc(#{math.div(-$timeline-state-size, 2)} - 15px);
  margin-right: space(2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $card-color-dark;
  &::before {
    @include font-icon;
    font-size: rem(20px);
    font-weight: 700;
    margin-left: 3px;
  }
  &.c-timeline-card__state--attachment {
    margin-left: calc(#{math.div(-$timeline-state-size, 2)} - 27px);
  }
}

$states: (
  approval   : '\f164',
  ready      : '\f164',
  draft      : '\f573',
  review     : '\f002',
  score      : '\f559',
  attachment : '\f0c6',
  comment    : '\f075',
  unscored   : '\f359',
  progress   : '\f252',
);

@mixin card-border-radius {
  border-radius: $border-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@each $state-name, $state-code in $states {
  .c-timeline-card__state[class*="#{$state-name}"] {
    &::before {
      content: $state-code;
      /* @if ($state-name == ready) {
        color: $brand-success;
      } */
    }
  }
}

.c-timeline-card__state--dispute {
  background: $brand-warning;
  border-color: $brand-warning;
}

.c-timeline-card__wrapper {
  flex: 1 1 auto;
  background: $white;
  position: relative;
  z-index: 10;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.c-timeline-card__edit {
  padding: space(2);
}

.c-timeline-card__footer,
.c-timeline-card__header {
  display: flex;
  align-items: center;
}

.c-timeline-card__header {
  background: $card-header-bg-l;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  min-height: $timeline-state-size - $card-border-width;
  position: relative;
  transition: .25s background ease-in-out;
}

.c-timeline-card__title {
  @include h4;
  color: $brand-accent-2;
  margin: 0;
  padding-left: space(2);
}

.c-timeline-card__context {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: rem(14px);
  line-height: line-height(16, 14);
  padding-right: space(2);
}

.c-timeline-card__context .c-dropdown {
  font-size: 18px;
  color: $gray-700;
  transition: .05s ease-in-out;
  transform-origin: center center;
  &:hover {
    color: $black;
    transform: scale(1.2);
  }
}

.c-timeline-card__body {
  padding: space(2);
}

.c-timeline-card__footer {
  padding: space(2);
  font-size: rem(13px);
  line-height: line-height(16, 13);
  background: $white;
  color: $gray-600;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-top: 1px solid $gray-200;
}

.c-timeline-card__attachements {
  border-top: $card-border-width solid $gray-200;
}

.c-timeline-card__date {
  margin-left: auto;
}

/* .c-timeline-card__user { } */

// Callout Variant
.c-timeline-card--highlight,
.c-timeline-card--current,
.c-timeline-card--callout,
.c-timeline-card--status {
  margin-bottom: space(6);
  .c-timeline-card__state,
  .c-timeline-card__wrapper,
  .c-timeline-card__header {
    background: $brand-accent-2;
    color: $white;
  }
  .c-timeline-card__wrapper {
    border-color: $brand-accent-2;
  }
  .c-timeline-card__header {
    border-color: $white;
  }
  .c-timeline-card__title {
    color: $white;
  }
}

@mixin timeline-card($accent, $text, $change-bg: true) {
  @content;
  &::before {
    background: $accent;
  }
  .c-timeline-card__state,
  .c-timeline-card__wrapper,
  .c-timeline-card__header {
    @if ($change-bg) { background: $accent; }
    color: darken($text, 5%);
  }
  .c-timeline-card__state,
  .c-timeline-card__wrapper {
    border-color: $accent;
  }
  .c-timeline-card__header {
    border-color: $text;
  }
  .c-timeline-card__title {
    color: darken($text, 5%);
  }
}

.c-timeline-card--current,
.c-timeline-card--callout {
  @include timeline-card($brand-accent-2, $white, true) {
    margin-bottom: space(6);
  };
}

$card-states: (
  success: (
    accent: $brand-success,
    text: $brand-success
  ),
  danger: (
    accent: $brand-danger,
    text: $brand-danger
  ),
  warning: (
    accent: $brand-warning,
    text: $brand-warning
  ),
  dispute: (
    accent: $brand-warning,
    text: $brand-warning
  ),
);

@each $state-name, $state in $card-states {
  .c-timeline-card--#{$state-name} {
    @include timeline-card(map-get($state, accent), map-get($state, text), false);
  }
}

.c-timeline-card--attachment {
  margin-top: (-#{space(6)});
  margin-left: .75rem;
}


.c-timeline-card--attachment .c-timeline-card__wrapper,
.c-timeline-card--attachment .c-timeline-card__body {
  border-top: 1px solid $brand-blue-d;
}

.c-timeline-card--attachment .c-timeline-card__header {
  background: $gray-050;
}

.c-timeline-card__file-list {
  @include list-unstyled;
  margin-bottom: 0;
}
