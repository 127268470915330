@import '../helpers';

.c-timeline {
  background: $card-bg-d;
  height: 100%;
  padding: 15px 15px space(10) 15px;
  border-left: 2px solid $brand-blue-d;
}

.c-timeline--light-bg {
  background: $white;
}

.c-timeline--spacious .c-timeline-card {
  margin-bottom: space(6);
}
