@import '../helpers';

.l-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.l-page__header {
  position: relative;
}

.l-page__alerts { }

.l-page__header nav {
  display: flex;
  flex-wrap: wrap;
}

.l-page main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.l-page__content {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
}

.l-page__content > div {
  flex: 1 1 auto;
  display: flex;
  max-height: 100%;
}

.l-page__pane {
  padding-right: space(6);
}

.l-page__aside {
  &.affixed {
    top: 0;
    bottom: 0;
    overflow: auto;
    @include scrollbar;
    .c-aside__section:last-of-type {
      margin-bottom: 0
    }
  }
}
// .l-page__aside--right { }

.l-page__aside ~ .l-page__main {
  width: $aside-sibling-width;
}

.l-page__pane,
.l-page__aside {
  overflow: auto;
  padding-bottom: space(6);
}

.l-page.with-affixed-elements {
  .l-page__aside  {
    position: fixed;
    top: 0;
  }

  .l-page__aside ~ .l-page__main  {
    padding-left: $aside-width;
    width: 100%;
  }
}
