@import '../helpers';

$viz-summary-intra-space: space(2);

.c-viz-summary {
  @include list-unstyled;
  display: flex;
}

.c-viz-summary--inline {
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: (-$viz-summary-intra-space);
  margin-left: (-$viz-summary-intra-space);
}

.c-viz-summary--columnar {
  flex: 1 1 auto;
  flex-direction: column;
}

.c-viz-summary__item {
  display: flex;
  justify-content: center;
  padding-right: $viz-summary-intra-space;
  padding-left: $viz-summary-intra-space;
  margin-bottom: space(6);
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
    margin-bottom: space(4);
  }
}

.c-viz-summary__item--columnar {
  flex-direction: column;
  align-items: flex-start;
}

.c-viz-summary__item--roll-up {
  @include media-breakpoint-up(md) {
    border-right: 5px solid $gray-100;
    margin-right: space(5);
    padding-right: space(6);
  }
}

.c-viz-summary__item--roll-up .c-metric__title {
  font-weight: 700;
}

.c-viz-summary__title {
  margin-bottom: space(2);
}

.c-viz-summary__explanation {
  margin-bottom: space(2);
  margin-top: -(#{space(2)});
}
