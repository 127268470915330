@import '../helpers';

$filters-width: 50px;

.o-filtered-content {
  position: relative;
  &::after {
    content: '';
    display: block;
    background: rgba($white, .75);
    position: absolute;
    z-index: 110;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .25s background ease-in-out;
  }
  &.filters-collapsed {
    &::after {
      background: transparent;
    }
  }
}

.o-filtered-content__wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  position: relative;
}

.o-filtered-content__results {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-right: calc(#{$filters-width});
}

.o-filtered-content__results-main {
  padding-right: space(4);
}

.o-filtered-content__results-summary {
  flex: 1 1 auto;
}

.o-filtered-content__filters {
  width: $filters-width;
  position: absolute;
  background: rgba($gray-100, 95%);
  z-index: 120;
  top: 0;
  bottom: 0;
  right: 0;
}

.o-filtered-content__results-header {
  display: flex;
  align-items: baseline;
  margin-bottom: space(4);
  margin-top: space(2);
}

.o-filtered-content__active-summary .container {
  padding-right: calc(#{$filters-width} + 15px);
}
