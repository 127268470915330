@import '../helpers';

.c-inline-link {
  display: inline-flex;
  align-items: baseline;
  color: $link-color;
  cursor: pointer;
  padding-left: 2px;
  margin-right: -2px;
  &:hover {
    color: $link-hover-color;
  }
}

.c-inline-link [class*='fa'] {
  margin-right: space(1);
}
