@import '../helpers';

// copied with minimal modification from https://gorails.com/episodes/at-mentions-with-actiontext
.tribute-container {
  border-radius: 4px;
  border: 1px solid $brand-primary;
  box-shadow: 0 0 4px rgba(0,0,0,0.1), 0 5px 20px rgba(0,0,0,0.05);
  max-height: 50vh;
  overflow: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    background: #fff;
    padding: 0.2rem 1rem;
    min-width: 15rem;
    max-width: 100%;
  }

  .highlight {
    background: $brand-primary;
    color: #fff;

    span {
      font-weight: bold;
    }
  }
}
