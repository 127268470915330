@import '../helpers';

.text--md {
  @include text-md;
}

.text--lg {
  @include text-lg;
}

.text--xl {
  @include text-xl;
}

.uppercase {
  text-transform: uppercase;
}

$colors: (
  link: $link-color,
);

@each $name, $color in $colors {
  .t--#{$name} {
    color: $color !important;
  }
}
