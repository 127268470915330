@import '../../helpers';

.col-form-label,
.form-control-label,
label.form-label {
  display: block;
  margin-bottom: space(1);
  @include font-bold;
  abbr {
    text-decoration: none;
    color: $brand-danger;
  }
}

.form-check-label {
  cursor: pointer;
}
