// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Variables
// ---------------------------------------------------------------------------
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and
// $modal-content-box-shadow-xs.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$font-size-default: 16px;

$font-weights: (
  light    : 300,
  normal   : 400,
  default  : 400,
  semibold : 600,
  bold     : 700,
  extrabold: 800
) !default;

$heading-sizes: (
  1: 36px,
  2: 30px,
  3: 24px,
  4: 18px,
  5: 16px,
) !default;

$metric-size: 24 !default;

$badge-min-width: 125px;

$icon-sizes: (
  sm: 14px,
  md: 24px,
  lg: 34px,
  xl: 64px,
  xxl: 100px,
);

// Aside
$aside-gutter-width: 15px;
$aside-width: 475px;
$aside-rule-height: 6px;
$aside-sibling-width: calc(100% - #{$aside-width} + 2px);

// Custom scrollbar
$default-scrollbar-width: 10px;

// Filters
$filter-form-width-sm: 75vw;
$filter-form-width-md: 60vw;
$filter-form-width-lg: 45vw;
$filter-form-width-xl: 30vw;
$filters-width: 50px;

// Forms
$textarea-min-height: 200px
