@import '../../helpers';

.table th {
  border-top: 0;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:focus {
  &,
  td {
    &:not(:last-of-type) {
      border-right-color: $white;
    }
  }
}

.table-hover tbody tr:active {
  background-color: $brand-accent-2;
}

.table--in-cell {
  tr td,
  tr th {
    color: $gray-600;
    &:first-of-type {
      border-left: 0;
    }
    &:last-of-type {
      border-right: 0;
    }
  }
}

.table-striped td {
  padding: 0;
  ul li {
    padding: 3px space(1);
    &:nth-of-type(even) {
      background: $gray-050;
    }
  }
}
@import '../../helpers';

.table-responsive {
  @include scrollbar;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 30px;
    background: $white;
    background: linear-gradient(90deg, rgba($white,0) 0%, rgba($white,1) 100%);
  }
}
