@import '../helpers';

.c-login {
  background: $gray-050;
  border: 1px solid $gray-100;
  border-top: 0;
  padding: space(8) space(4) space(6) space(4);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.c-login__actions {
  margin-top: space(6);
}
