@import '../helpers';

.c-metric-value {
  @include data-metric;
  margin-right: space(4);
  &:empty {
    display: none;
  }
}

.c-metric-value--light {
  color: $white;
}

.c-metric-value--lg {
  @include h1;
  color: $black;
}

.c-metric-value--primary {
  color: $brand-primary;
  margin-right: space(2);
}

.c-metric-value--light {
  color: $card-bg;
}
