@import '../helpers';

.no-border {
  border: 0 !important;
}

.no-border--x {
  border-right: 0 !important;
  border-left: 0 !important;
}

.no-border--y {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

$directions: (
  t: top,
  b: bottom,
  r: right,
  l: left,
);

$colors: (
  secondary: $brand-secondary,
  "gray": $gray-500,
);

@each $code, $dir in $directions {
  .no-border--#{$code} {
    border-#{$dir}: 0 !important;
  }
}

@each $code, $color in $colors {
  .heavy-border-l--#{$code} {
    border-left: 8px solid $color;
  }
}
