@import '../helpers';

// table headers
.c-strategy-header--primary {
  color: $gray-900;
  font-size: 20px;
  line-height: 36px;
}

.c-strategy-header--secondary {
  color: $gray-800;
}

.c-edit-widget {
  display: inline-block;
  min-width: 10px;
}
