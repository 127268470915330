@import '../helpers';

.c-signature {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-left: space(4);
  margin-top: space(2);
  margin-bottom: space(2);
}

.c-signature__icon {
  font-size: 80px;
  color: $brand-secondary;
  margin-right: space(2);
}

.c-signature__content {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: space(4);
}

.c-signature__title {
  position: absolute;
  transform: rotate(90deg);
  width: 10px;
  left: -20px;
  top: -5px;
  font-weight: 800;
  text-transform: uppercase;
  color: $brand-secondary;
}

.c-signature__date {
  margin-bottom: 0;
}

.c-signature__user {
  margin-bottom: 0;
  font-weight: 600;
}

.c-signature__info {
  margin-bottom: space(1);
  display: flex;
  flex-direction: column;
}

.c-signature__role,
.c-signature__org {
  font-size: rem(13px);
  color: $gray-600;
  display: block;
}

.c-signature__role {
}
