@import '../helpers';

$heading-z-index: 100;

.c-heading {
  flex-wrap: wrap
}

.c-heading--sticky {
  position: sticky;
  top: 0;
  z-index: $heading-z-index;
  background: rgba($white, .98);
  background: linear-gradient(to bottom, rgba($white,1) 75%,rgba($white,0) 100%);
  padding-top: space(1);
  padding-bottom: space(1);
}

.c-heading--bordered {
  padding-bottom: space(1);
  border-bottom: 1px solid $gray-300;
  margin-bottom: space(2);
}

.c-heading--solid-bg {
  background: $white;
}

.c-heading--main {
  z-index: $heading-z-index + 1;
  display: flex;
  align-items: center;
  padding-bottom: space(2);
}

.c-heading--no-bg {
  background: transparent;
}
