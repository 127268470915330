@import '../helpers';

.c-detail-summary {
  background: $gray-050;
  border-bottom: 2px solid $gray-100;
  flex: 0 1 auto;
}

.c-detail-summary__container {
  display: flex;
  flex-wrap: wrap;
}

.c-detail-summary__container > h2 {
  padding: space(2) space(5) space(2);
  background: $gray-200;
  flex: 1 1 auto;
  margin: (-#{space(4)}) (-#{space(4)}) 0 (-#{space(4)});
}

.c-detail-summary__container--padded {
  padding-top: space(4);
  padding-bottom: space(4);
}

.c-detail-summary__container--padded-x {
  padding-left: space(4);
  padding-right: space(4);
}

.c-detail-summary__container--min-height {
  min-height: 150px;
}

.c-detail-summary__section {
  border-right: 2px solid $gray-100;
}

.c-detail-summary__section--full-width {
  min-width: 100%;
  display: flex;
  border: 0;
  &:last-child .c-detail-summary__row {
    border-bottom: 0;
  }
}

.c-detail-summary__section--full-width .c-detail-summary__row {
}

.c-detail-summary__row--2 {
}

.c-detail-summary__row {
  border-bottom: 2px solid $gray-100;
  border-right: 1px solid $gray-100;
  padding: space(3);
  &:last-child {
    border-right: 0;
  }
  @include media-breakpoint-down(sm) {
    border-right: 0;
  }
}

.c-detail-summary__row--full-height {
  flex: 1 1 auto;
}

.c-detail-summary__title {
  @include font-default(rem(20px));
  text-transform: uppercase;
}

.c-detail-summary__value {
  display: inline-flex;
  align-items: center;
}

.c-detail-summary__value .c-store-label__icon {
  color: black;
  font-size: 50px;
}

.c-detail-summary__address {
  flex-direction: column;
  align-items: flex-start;
  span {
    display: block;
  }
}
