@import '../helpers';

.o-container {
  display: flex;
  padding-bottom: space(8);
  min-width: 100%;
}

.o-container--columnar {
  flex-direction: column;
}

.o-container--fixed {
  width: map-get($grid-breakpoints, xl);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.o-container--padded {
  padding: space(4);
}

.o-container--padded-x {
  padding-left: space(4);
  padding-right: space(4);
}

.o-container--padded-y {
  padding-top: space(4);
  padding-bottom: space(4);
}
