// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Custom Bootstrap Variables
// ---------------------------------------------------------------------------
// Available variables:
// https://github.com/twbs/bootstrap/blob/v4.1.2/scss/_variables.scss
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Colors
@import './colors';
@import './spacing';
@import '../_tools/functions';

$font-family-sans-serif: "Open Sans", sans-serif;
$headings-font-family: "Montserrat", sans-serif;

// Breadcrumbs
$breadcrumb-padding-y: space(2);
$breadcrumb-padding-x: space(4);
$breadcrumb-bg: transparent;
$breadcrumb-active-color: $brand-accent;
$breadcrumb-margin-bottom: 0;

// Buttons
$btn-padding-x: 2rem;
$btn-padding-y: .75rem;

// Tables
/* $table-accent-bg: $gray-050; */
$table-hover-color: $white;
$table-hover-bg: $brand-primary;
$table-active-bg: $table-hover-bg;
$table-striped-order: even;

// Dropdowns
$dropdown-bg:               $gray-050;
$dropdown-link-color:       darken($link-color, 5%);
$dropdown-link-hover-color: darken($dropdown-link-color, 5%);
$dropdown-link-hover-bg:    darken($dropdown-bg, 5%);
$dropdown-border-width:     1px;
$dropdown-border-color:     $gray-200;

// Modals
$modal-header-border-color: $brand-blue-d;
$modal-content-border-color: none;
$modal-footer-border-color: $brand-blue-d;

// Tooltips
$tooltip-opacity: 1;
