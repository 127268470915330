@import '../helpers';

@each $name, $size in $icon-sizes {
  .icon--#{$name} {
    &,
    &::before {
      font-size: $size;
    }
  }
}

@include init-font('icons');
