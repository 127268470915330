@use "sass:math";
@import '../helpers';

.tooltip .tooltip-inner {
  @include tooltip;
  border-color: $black;
  background: $white;
  text-align: left;
  padding: space(4);
  color: $black;
  font-size: rem(13px);
  line-height: math.div(16, 13);
}

.tooltip span {
  display: block;
  color: $black;
}

.tooltip .tooltip-title {
  @include h3;
  margin-bottom: space(1);
}
