@use "sass:math";
@import '../helpers';

.c-page-heading {
  background: $page-heading-color;
  color: $white;
  padding: 0;
  padding-top: space(1);
}

.c-page-heading__primary-content,
.c-page-heading__secondary-content {
  padding: space(1) 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-page-heading__primary-content {
  background: $page-heading-color;
}

.c-page-heading__secondary-content {
  background: $page-heading-secondary-color;
  padding-top: space(2);
  padding-bottom: space(2);
}

.c-page-heading__nav ul {
  display: flex;
  align-items: center;
  @include list-unstyled;
  margin: 0;
}

.c-page-heading__nav li {
  margin-right: space(3);
  padding-right: space(3);
  border-right: 1px solid $gray-500;
  font-size: 18px;
  line-height: math.div(20, 18);
  &:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.c-page-heading__actions {
  margin-left: auto;
  margin-top: space(2);
  margin-bottom: space(2);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.c-page-heading__actions button i {
  &:not(.closed) {
    transform: rotate(180deg);
  }
}

.c-page-heading__action {
  margin-left: space(2);
  margin-bottom: space(2);
  width: 100%;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    width: auto;
  }
}

.c-page-heading__title,
.c-page-heading__title--secondary {
  color: $white;
}

.c-page-heading__title {
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
}

.c-page-heading__title span {
  margin-left: space(2);
  font-size: 22px;
  line-height: math.div(26, 22);
}

.c-page-heading__title--secondary {
  margin-bottom: 0;
}

.c-page-heading__title-edit {
  display: flex;
  align-items: center;
  &,
  a {
    @include font-base($link-color);
    font-weight: normal;
    font-size: 13px;
    line-height: math.div(15, 13);
    margin: 0 space(1);
    &:focus,
    &:hover {
      color: $white;
    }
  }
}
