@import '../helpers';

.c-columns {
  padding: space(4) space(4) space(6) space(4);
  column-count: 3;
  column-gap: 100px;
  column-rule: 1px solid $gray-100;
}

.c-columns__item {
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: space(3);
}

.c-columns dd {
  margin-bottom: 0;
}
