@import '../helpers';

.c-metric-donut {
  display: flex;
  flex: 1 1 auto;
}

.c-metric-donut--inline {
  flex: 0 0 auto;
}

.c-metric-donut--stacked {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
}

.c-metric-donut__wrapper {
  position: relative;
}

.c-metric-donut__wrapper--sm {
  display: flex;
  align-items: center;
}

.c-metric-donut__wrapper--sm .c-metric-value {
  position: static;
  transform: none;
}

.c-metric-donut--sm {
  width: auto;
}

.c-metric-donut--centered {
  @include media-breakpoint-up(xl) {
    justify-content: center;
  }
}

/* .c-metric-donut { } */

.c-metric-donut .c-metric__viz-wrapper {
  width: auto;
}

.c-metric-donut .c-metric__viz-wrapper--val-inside {
  position: relative;
}

.c-metric-donut .c-metric__viz-wrapper--val-inside {
  position: relative;
  .c-metric-value {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: $black;
    font-size: rem(30px);
    text-align: center;
    z-index: 1;
  }
  .c-metric-value--no-data {
    @include data-metric-no-data()
  }
}
